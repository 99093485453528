:root {
  --poppins-regular: 'PoppinsRegular';
  --font-style-normal: normal;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-17: 17.5px;
  --font-size-36: 36px;
  --font-size-64: 64px;
  --font-weight-600: 600;
  --line-height-21: 21px;
  --line-height-24: 24px;
  --line-height-44: 44px;
  --line-height-76: 76px;
  --line-height-130-percent: 130%;
  --home-title-spacing: -0.5px;
  --home-sub-text-spacing: 0.1px;
  --service-box-label-spacing: -0.02em;
  --white-color: #ffffff;
  --login-btn-color: #7649a0;
  --sub-text-color: #0a141b;
  --home-background: linear-gradient(92.58deg, #592c82 0%, #b655cc 100%);
  --home-blue-background: #f5f8fa;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Reusable Common classes */
.d-flex {
  display: flex;
}
.float-right {
  float: right;
}
.align-items-center {
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.align-center {
  text-align: center;
}
.mr-8 {
  margin-right: 8px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-100 {
  margin-right: 100px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-38 {
  margin-top: 38px;
}
.mt-48 {
  margin-top: 48px;
}

.mt-50 {
  margin-top: 50px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-97 {
  margin-top: 97px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-40 {
  margin-bottom: 40px;
}
.pt-13 {
  padding-top: 13px;
}

.pt-40 {
  padding-top: 40px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-84 {
  padding-bottom: 84px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-78 {
  padding-left: 78px;
}

.pl-100 {
  padding-left: 100px;
}
.pl-156 {
  padding-left: 156px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-115 {
  margin-left: 115px;
}

.pr-136 {
  padding-right: 136px;
}
.w-100 {
  width: 100% !important;
}
