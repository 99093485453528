.number-box-container {
  padding-left: 156px;
  padding-right: 136px;
}
.title-text {
  background-image: linear-gradient(92.58deg, #592c82 0%, #b655cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  display: -webkit-box;
}

.sub-text {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-style-normal);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--home-sub-text-spacing);
  color: var(--sub-text-color);
}

.road-runner-img-div {
  padding-left: 115px;
  padding-top: 50px;
}

.text-container {
  padding-left: 156px;
  padding-top: 24px;
}

.dashboard-bg-img {
  background-image: url(../../assets/images/dashboard-img.svg);
  background-position: center;
  min-height: 100%;
  background-repeat: no-repeat;
  mix-blend-mode: var(--font-style-normal);
  background-size: contain;
  max-height: 100%;
}
.started-btn {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-14);
  cursor: pointer;
  line-height: var(--line-height-21);
  padding: 8px 24px 8px 24px;
  align-items: center;
  color: var(--white-color);
  background: var(--login-btn-color);
  border-radius: 8px;
  border: none;
}
.started-btn:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

/* Media query for Desktop screens */
@media only screen and (min-width: 954px) {
  .desk-pt-48 {
    padding-top: 48px;
  }
}

.mob-view {
  display: none;
}

/* Media query for mobile screens */
@media only screen and (max-width: 480px) {
  .desktop-view {
    display: none;
  }
}

/* Media query for Desktop screens */
@media only screen and (min-width: 960px) {
  .mob-view {
    display: none;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 959px) and (orientation: landscape) {
  /* For landscape layouts only */
  .desktop-view {
    display: none;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 959px) and (orientation: portrait) {
  .desktop-view {
    display: none;
  }
}

/* .top-bg {
  background: #f5f8fa;
  border-radius: 0px 0px 40px 40px;
}
.bottom-bg {
  background: linear-gradient(92.58deg, #592c82 0%, #b655cc 100%);
} */

.wrapper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-content {
  position: relative;
  z-index: 100;
}

/* .background-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  height: 100%;
  width: 100%;
  background: linear-gradient(92.58deg, #592c82 0%, #b655cc 100%);
} */

.blue-container {
  background: #f5f8fa;
  padding: 0px 16px 0px 16px;
}

.mob-title-text {
  font-family: var(--poppins-regular);
  font-size: var(--font-size-36);
  line-height: var(--line-height-44);
  background: linear-gradient(92.58deg, #592c82 0%, #b655cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: var(--font-style-normal);
  font-weight: var(--font-style-normal);
  letter-spacing: var(--home-title-spacing);
}

.mob-sub-text {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-style-normal);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--home-sub-text-spacing);
  color: var(--sub-text-color);
}

.mob-started-btn {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-14);
  cursor: pointer;
  line-height: var(--line-height-21);
  width: 100%;
  padding: 10px 131px 10px 131px;
  align-items: center;
  color: var(--white-color);
  background: var(--login-btn-color);
  border-radius: 8px;
  border: none;
}

.purple-container {
  background: linear-gradient(92.58deg, #592c82 0%, #b655cc 100%);

  padding: 0px 16px 0px 16px;
}

@media only screen and (max-width: 1290px) {
  .dashboard-bg-img {
    background-position: initial;
  }
}
